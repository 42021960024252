import { Injectable, TemplateRef } from '@angular/core';
import { DialogRef, DialogSettings } from '@progress/kendo-angular-dialog';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ShippingAddressUndeliverableComponent } from 'src/app/shipping/component/shipping-address-undeliverable/shipping-address-undeliverable.component';
import { IShippingMethod } from 'src/app/shipping/model';
import { AutoShipSubscriptionInfoComponent } from '../component';
import { AutoShipAddItemComponent } from '../component/auto-ship-add-item/auto-ship-add-item.component';
import { AutoShipAddToCartComponent } from '../component/auto-ship-add-to-cart/auto-ship-add-to-cart.component';
import { AutoShipAddressSelectionComponent } from '../component/auto-ship-address-selection/auto-ship-address-selection.component';
import { AutoShipDateSelectionComponent } from '../component/auto-ship-date-selection/auto-ship-date-selection.component';
import { AutoShipDeactivationComponent } from '../component/auto-ship-deactivation/auto-ship-deactivation.component';
import { AutoShipItemRescheduleComponent } from '../component/auto-ship-item-reschedule/auto-ship-item-reschedule.component';
import { AutoShipPriceInfoComponent } from '../component/auto-ship-price-info/auto-ship-price-info.component';
import { AutoShipScheduleComponent } from '../component/auto-ship-schedule/auto-ship-schedule.component';
// tslint:disable-next-line: max-line-length
import { AutoShipShippingMethodSelectionComponent } from '../component/auto-ship-shipping-method-selection/auto-ship-shipping-method-selection.component';
import { IAutoShip } from '../model/auto-ship';
import { IAutoShipAddress } from '../model/auto-ship-address';
import { IAutoShipItem } from '../model/auto-ship-item';

@Injectable({
  providedIn: 'root',
})
export class AutoShipDialogService {
  constructor(private readonly dialogService: DialogService) { }

  open(options: DialogSettings): DialogRef {
    return this.dialogService.open(options);
  }

  openDateSelectionDialog(shippingDay: number, onSelected: (shippingDay: number) => void = null, onClose: () => void = null): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Shipping Date',
      content: AutoShipDateSelectionComponent,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: AutoShipDateSelectionComponent = dialogRef.content.instance;
    componentRef.shippingDay = shippingDay;
    componentRef.selectionComplete.subscribe((shipDay: number) => {
      if (onSelected) {
        onSelected(shipDay);
      }
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openAddressSelectionDialog(
    shippingAddresses: IAutoShipAddress[],
    shippingDay: string,
    autoShipNumber: number,
    autoShipItemId: string = null,
    onSelected: () => void = null,
    onClose: () => void = null
  ): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Shipping Address',
      content: AutoShipAddressSelectionComponent,
      // height: 600,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: AutoShipAddressSelectionComponent = dialogRef.content.instance;
    componentRef.autoShipNumber = autoShipNumber;
    componentRef.autoShipItemId = autoShipItemId;
    componentRef.shippingDay = shippingDay;
    componentRef.shippingAddresses = shippingAddresses;

    componentRef.addressChanged.subscribe(() => {
      if (onSelected) {
        onSelected();
      }
      dialogRef.close();
    });

    componentRef.isUndeliverableAddress.subscribe(() => {
      this.showUndeliverableAddressDialog();
    });

    dialogRef.result.subscribe((result: any) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  showUndeliverableAddressDialog() {
    const dialogRe1f: DialogRef = this.dialogService.open({
      title: `Shipping Address Require Attention`,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingAddressUndeliverableComponent,
    });
  }

  openShippingMethodSelectionDialog(
    selectedMethod: string,
    shippingMethods: IShippingMethod[],
    shippingDay: string,
    onSelected: (shippingMethod: string) => void = null,
    onClose: () => void = null
  ): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Shipping Method',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: AutoShipShippingMethodSelectionComponent,
    });

    const shippingSelection: AutoShipShippingMethodSelectionComponent = dialogRef.content.instance;
    shippingSelection.selectedMethod = selectedMethod;
    shippingSelection.shippingMethods = shippingMethods;
    shippingSelection.shippingDay = shippingDay;
    shippingSelection.methodSelected.subscribe((shippingMethod: string) => {
      if (onSelected) {
        onSelected(shippingMethod);
      }
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openDeactivationDialog(
    itemName: string,
    shippingDay: string,
    onDeactivate: () => void,
    onReschedule: () => void,
    onClose: () => void = null
  ): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Deactivate Item',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: AutoShipDeactivationComponent,
    });

    const shippingSelection: AutoShipDeactivationComponent = dialogRef.content.instance;
    shippingSelection.itemName = itemName;
    shippingSelection.shippingDay = shippingDay;

    shippingSelection.deactivate.subscribe(() => {
      onDeactivate();
      dialogRef.close();
    });

    shippingSelection.reschedule.subscribe(() => {
      onReschedule();
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openAddToCartDialog(item: IAutoShipItem, sourcePlacement: string, sourceIndex: string, onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Need It Now?',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: AutoShipAddToCartComponent,
    });

    const componentRef: AutoShipAddToCartComponent = dialogRef.content.instance;
    componentRef.item = item;
    componentRef.sourcePlacement = sourcePlacement;
    componentRef.sourceIndex = sourceIndex;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openShippingInformationDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Pricing on AutoShip & Save items',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: AutoShipPriceInfoComponent,
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openItemScheduleDialog(
    nextShipDate: Date,
    autoShipNumber: number,
    autoShipItemId: string = '',
    autoShipItemName: string = '',
    onClose: () => void = null
  ): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Reschedule for Later Date',
      content: AutoShipItemRescheduleComponent,
      minHeight: 200,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: AutoShipItemRescheduleComponent = dialogRef.content.instance;
    componentRef.nextShipDate = nextShipDate;
    componentRef.autoShipNumber = autoShipNumber;
    componentRef.autoShipItemId = autoShipItemId;
    componentRef.autoShipItemName = autoShipItemName;

    componentRef.closeDialog.subscribe((newDate: string) => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result: any) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openScheduleDialog(autoShip: IAutoShip, onClose: () => void = null): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Shipment Schedule',
      content: AutoShipScheduleComponent,
      height: 500,
      width: 800,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: AutoShipScheduleComponent = dialogRef.content.instance;
    componentRef.autoShip = autoShip;

    dialogRef.result.subscribe((result: any) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openAddItemDialog(
    autoShipNumber: number,
    alias: string,
    shippingAddress: string,
    shippingDay: string,
    onItemAdded: (itemNumber: string) => void,
    onClose: () => void = null
  ): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Add Item',
      content: AutoShipAddItemComponent,
      height: 600,
      width: 1040,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: AutoShipAddItemComponent = dialogRef.content.instance;
    componentRef.autoShipNumber = autoShipNumber;
    componentRef.shippingDay = shippingDay;
    componentRef.shippingAddress = shippingAddress;
    componentRef.alias = alias;

    componentRef.itemAdded.subscribe((itemNumber) => {
      if (onItemAdded) {
        onItemAdded(itemNumber);
      }
      dialogRef.close();
    });

    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result: any) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openLeDollarsDialog(templateRef: TemplateRef<any>, onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'LE Dollars on AutoShip & Save',
      width: 500,
      maxHeight: '90%',
      maxWidth: '90%',
      content: templateRef,
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openSubscriptionInformationDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Premier Auto Renew',
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: AutoShipSubscriptionInfoComponent,
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }
}
