import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ItemConfigurationService } from 'src/app/cart-common/service/item-configuration.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IPaymentOption } from 'src/app/payment/model';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { IAutoShipAddress } from '../../model/auto-ship-address';
import { IAutoShipChange } from '../../model/auto-ship-change';
import { IAutoShipDelivery } from '../../model/auto-ship-delivery';
import { IAutoShipItem } from '../../model/auto-ship-item';
import { AutoShipService, AutoShipValuesService } from '../../service';
import { AutoShipDialogService } from '../../service/auto-ship-dialog.service';

@Component({
  selector: 'lef-auto-ship-item',
  templateUrl: './auto-ship-item.component.html',
  styleUrls: ['./auto-ship-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoShipItemComponent implements OnInit, OnChanges {
  @Input()
  item: IAutoShipItem;

  @Input()
  delivery: IAutoShipDelivery;

  @Input()
  shippingDay: string;

  @Output()
  updateAutoShip: EventEmitter<void> = new EventEmitter();

  frequencyValues: Array<{ value: number; text: string }> = [];
  frequencyValue: { value: number; text: string } = { value: 0, text: '' };

  get itemUrl(): string {
    return this.itemConfig.getItemUrl(this.item);
  }

  get intervalId(): string {
    return this.item ? 'autoship-interval-' + this.item.itemNumber : '';
  }

  constructor(
    private readonly config: CoreConfiguration,
    private readonly itemConfig: ItemConfigurationService,
    private readonly autoShipValuesService: AutoShipValuesService,
    private readonly dialogService: AutoShipDialogService,
    private readonly autoShipService: AutoShipService,
    private readonly htmlUtilityService: HtmlUtilityService
  ) { }

  ngOnInit() {
    this.frequencyValues = this.autoShipValuesService.autoShipFrequencyFullValues;
    this.updateSelectedFrequencyValue();
  }

  ngOnChanges(changes: SimpleChanges) { }

  addToCart() {
    const sourcePlacement = this.delivery.deliveryIndex >= 0 ? `${this.config.sourceTypeMap.list}${(1 + this.delivery.deliveryIndex)?.toString()}` : '';
    const sourceIndex = (this.delivery.items.indexOf(this.item) + 1).toString() ?? '';

    if (!this.item.priceBreakList?.length) {
      this.autoShipService.getItemPriceBreakList(this.item.itemNumber).subscribe((list) => {
        this.item.priceBreakList = list;
        this.dialogService.openAddToCartDialog(this.item, sourcePlacement, sourceIndex);
      });
    } else {
      this.dialogService.openAddToCartDialog(this.item, sourcePlacement, sourceIndex);
    }
  }

  reschedule() {
    this.dialogService.openItemScheduleDialog(
      new Date(this.item.nextShipDate),
      this.delivery.autoShipNumber,
      this.item.autoShipItemId,
      this.item.friendlyName
    );
  }

  onPaymentSelected(paymentOption: IPaymentOption) {
    const paymentChange: IAutoShipChange = {
      paymentOptionId: paymentOption.id,
      autoShipNumber: this.delivery.autoShipNumber,
      autoShipItemId: this.item.autoShipItemId,
    };

    this.autoShipService.changePaymentMethod(paymentChange).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  showPriceInformation() {
    this.dialogService.openShippingInformationDialog();
  }

  deactivate() {
    this.dialogService.openDeactivationDialog(
      this.item.friendlyName,
      this.shippingDay,
      this.doDeactivate.bind(this),
      this.reschedule.bind(this)
    );
  }

  doDeactivate() {
    this.autoShipService.deactivateItem(this.item.autoShipItemId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  delete() {
    this.autoShipService.deleteItem(this.item.autoShipItemId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  reactivate() {
    this.autoShipService.reactivateItem(this.item.autoShipItemId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  updateInterval() {
    this.htmlUtilityService.setFocusPoint(this.intervalId);
    this.updateAutoShip.emit();
  }

  updateQuantity(newQuantity) {
    this.item.quantity = newQuantity;
    this.updateAutoShip.emit();
  }

  changeShippingAddress() {
    this.autoShipService.getShippingAddressList().subscribe((addressList) => {
      this.openShippingAddressDialog(addressList);
    });
  }

  openShippingAddressDialog(shippingAddresses: IAutoShipAddress[]) {
    shippingAddresses.forEach((a) => {
      a.isSelected = a.shipToNumber === this.delivery.shipToNumber ? true : false;
    });

    this.dialogService.openAddressSelectionDialog(
      shippingAddresses,
      this.shippingDay,
      this.delivery.autoShipNumber,
      this.item.autoShipItemId
    );
  }

  private updateSelectedFrequencyValue() {
    if (this.frequencyValues?.length && this.item?.interval) {
      this.frequencyValue = this.frequencyValues.find((v) => v.value === this.item.interval);
      if (!this.frequencyValue) {
        this.frequencyValue = { value: 0, text: '' };
      }
    } else {
      this.frequencyValue = { value: 0, text: '' };
    }
  }
}
