<div class="forgot-password-form" *ngIf="!forgotDialogSubmitted">
  <p class="mb-2 font-md">
    Please enter your e-mail address below to begin the password retrieval process.
    Remember that passwords are case-sensitive.
  </p>
  <form class="k-form" [formGroup]="forgotPasswordForm" (ngSubmit)="requestResetPassword()">
    <div>
      <div>
        <formly-form [form]="forgotPasswordForm" [fields]="forgotPasswordFormFields" [model]="forgotPasswordModel">
        </formly-form>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-warning inline-form-btn" [disabled]="!forgotPasswordForm.valid">
          Submit
        </button>
      </div>
    </div>
  </form>
  <div class="d-flex align-items-center justify-content-end">
    <span class="mr-2">Need help?</span><button type="button" class="btn btn-link">Contact Us</button>
  </div>
</div>
<div class="forgot-password-confirmation" *ngIf="forgotDialogSubmitted">
  <p class="font-md">
    If this is a registered email address with Life Extension, an email to reset your password has been sent to
    the address provided.
  </p>
  <div class="float-right">
    <button type="submit" class="btn btn-warning" (click)="close()">
      Close
    </button>
  </div>
</div>