import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Cart, ICart } from 'src/app/cart-common/model';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service/responsive.service';

@Component({
  selector: 'lef-cart-order-summary',
  templateUrl: './cart-order-summary.component.html',
  styleUrls: ['./cart-order-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartOrderSummaryComponent implements OnInit, OnDestroy {
  @Input() cart: Cart = new Cart();

  @Output() cartUpdated: EventEmitter<ICart> = new EventEmitter();
  @Output() checkout = new EventEmitter<void>();

  get showPremierDiscount(): boolean {
    return this.cart.lePremierRewardAmount && this.cart.lePremierRewardAmount > 0;
  }

  screenSize: ScreenSizes;
  screenSizes = ScreenSizes;

  private responsiveSubscription: Subscription;

  constructor(private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.subscribeToResponsive();
  }

  ngOnDestroy(): void {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
  }

  onShippingMethodUpdate(cart: Cart): void {
    this.cartUpdated.emit(cart);
  }

  onDiscountApplied(cart: Cart) {
    this.cartUpdated.emit(cart);
  }

  onCheckout() {
    this.checkout.emit();
  }

  private subscribeToResponsive() {
    this.responsiveSubscription = this.responsiveService.onResize$.subscribe((screenSize: ScreenSizes) => {
      this.screenSize = screenSize;
    });
    this.responsiveService.checkCurrentSize();
  }
}
