<div *ngIf="!loading && carouselItems && carouselItems.length">
  <h2 class="text-primary">{{title}}</h2>
  <ngx-slick-carousel class="productCarousel relatedProducts related-products" #slickModal="slick-carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let carouselItem of carouselItems" class="prod-slide">
      <lef-item-carousel-item [carouselItem]="carouselItem" [buttonText]="buttonText"
                              (addToCartClicked)="onAddToCartClicked($event)">
      </lef-item-carousel-item>
    </div>
  </ngx-slick-carousel>
</div>