import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ISourceInfo, SourceInfo } from 'src/app/cart-common/model/source-info';
import { CartService } from 'src/app/cart/service';
import { FloatingCartService } from 'src/app/common/service';
import { IOrderItem } from 'src/app/order-by-item/model/order-item';
import { PackagingSlipComponent } from 'src/app/returns/component/packaging-slip/packaging-slip.component';
import { ShippingLabelComponent } from 'src/app/returns/component/shipping-label/shipping-label.component';
import { DialogAlertMessageComponent } from 'src/app/shared/component/dialog-alert-message/dialog-alert-message.component';
import { CartNotificationService } from 'src/app/shared/service/cart-notification.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { BaseOrderDetails } from '../../model/base-order-details.model';
import { OrderDetail } from '../../model/order-detail.model';
import { OrderHistory } from '../../model/order-history.model';
import { WarehouseExpectedItem } from '../../model/warehouse-expected-item.model';
import { OrderHistoryService } from '../../service/order-history.service';

@Component({
  selector: 'lef-order-details',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsComponent extends BaseOrderDetails implements OnInit {
  @Input() embedded: boolean;
  @Input() orderNumber: number;
  @Input() includeOnHold: boolean;
  @Output() hideDetails: EventEmitter<void> = new EventEmitter();
  private shippingLabelDialogRef: DialogRef;
  orderNotFound: boolean;
  order = new OrderDetail();
  criteria: string;
  daysRange: number;

  private sourceInfo: ISourceInfo = new SourceInfo('Order Detail', '', '');

  constructor(
    router: Router,
    floatingCartService: FloatingCartService,
    cartService: CartService,
    trackingService: TrackingService,
    notificationService: CartNotificationService,
    loadingService: LoadingService,
    dialogService: DialogService,
    private readonly route: ActivatedRoute,
    private readonly orderHistoryService: OrderHistoryService,
    private location: Location,
    private readonly renderer: Renderer2
  ) {
    super(router, floatingCartService, cartService, trackingService, notificationService, loadingService, dialogService);
  }

  ngOnInit(): void {
    if (!this.embedded) {
      this.orderNumber = this.route.snapshot.params.orderNumber;
      const routeState = this.location.getState() as any;
      this.daysRange = routeState.daysRange;
      this.criteria = routeState.criteria;
    }
    this.loadOrderInfo();
  }

  loadOrderInfo() {
    this.loadingService.show();
    this.orderHistoryService.getOrder(this.orderNumber, '', this.includeOnHold).subscribe(
      (result: OrderHistory) => {
        this.orderNotFound = result.orders.length === 0;
        if (result.orders.length > 0) {
          this.order = result.orders[0];
        }
        this.loadingService.hide();
      },
      (error: any) => {
        this.loadingService.hide();
      }
    );
  }

  addAllItemsToCart() {
    const items: IOrderItem[] = [];
    this.order.shipments.forEach((shipment) => {
      shipment.items.forEach((item) => {
        items.push({
          itemNumber: item.itemNumber,
          quantity: 1,
          sourceInfo: this.sourceInfo
        });
      });
    });
    this.addToCart(items);
  }

  goBack() {
    if (this.embedded) {
      this.hideDetails.emit();
    } else {
      this.router.navigate([`/account/orderhistory`], {
        queryParams: { criteria: this.criteria, daysRange: this.daysRange },
      });
    }
  }

  printPackagingSlip(items: WarehouseExpectedItem[], actionTemplate: TemplateRef<any>) {
    this.shippingLabelDialogRef = this.dialogService.open({
      title: 'Packaging Slip',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: PackagingSlipComponent,
      actions: actionTemplate,
    });

    const printPackagingSlip: PackagingSlipComponent = this.shippingLabelDialogRef.content.instance;
    printPackagingSlip.orderNumber = this.order.orderNumber;
    printPackagingSlip.items = items;

    this.shippingLabelDialogRef.result.subscribe(() => {
      this.shippingLabelDialogRef = null;
    });
  }

  openShippingLabelDialog(actionTemplate: TemplateRef<any>): void {
    this.shippingLabelDialogRef = this.dialogService.open({
      title: 'Prepaid Shipping Label',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingLabelComponent,
      actions: actionTemplate,
    });

    const shippingLabel: ShippingLabelComponent = this.shippingLabelDialogRef.content.instance;
    shippingLabel.trackingNumber = this.order.trackingNumber;
    shippingLabel.autoPrint = true;

    this.shippingLabelDialogRef.result.subscribe(() => {
      this.shippingLabelDialogRef = null;
    });
  }

  showDeadlineReturnsDialog() {
    const message = `Because we've already processed your refund/exchange, you will be charged if we do not receive by expected return date`;
    this.showAlertMessage(`Deadline to Return Item(s)`, message);
  }

  printLabel() {
    const shippingLabel: ShippingLabelComponent = this.shippingLabelDialogRef.content.instance;
    shippingLabel.printLabel();
  }

  printSlip() {
    const printPackagingSlip: PackagingSlipComponent = this.shippingLabelDialogRef.content.instance;
    printPackagingSlip.print();
  }

  showAlertMessage(alertTitle: string, htmlMessage: string) {
    this.shippingLabelDialogRef = this.dialogService.open({
      title: alertTitle,
      width: 720,
      maxHeight: '90%',
      maxWidth: '90%',
      content: DialogAlertMessageComponent,
    });
    const alert: DialogAlertMessageComponent = this.shippingLabelDialogRef.content.instance;
    alert.htmlMessage = htmlMessage;

    this.shippingLabelDialogRef.result.subscribe((result) => {
      this.shippingLabelDialogRef = null;
    });
  }
}
