import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoginService } from 'src/app/login/service/login.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { IMenuLink } from '../../model/menu-link';

@Component({
  selector: 'lef-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss'],
})
export class HeaderAccountComponent implements OnInit {
  @Input() username = '';
  @Input() menuLinks: IMenuLink[] = [];
  @Input() menuOnHeader: boolean = false;

  @Output() linkClick: EventEmitter<IMenuLink> = new EventEmitter();

  constructor(
    private readonly loginService: LoginService,
    private readonly loading: LoadingService,
    private readonly router: Router,
    private readonly trackingService: TrackingService,
    private readonly config: CoreConfiguration
  ) { }

  ngOnInit(): void {
  }

  toggleAccountMenu() {
    var accountMenuPanel = document.getElementById("accountMenuPanel");
    if (accountMenuPanel.style.display == "none") {
      accountMenuPanel.style.display = "block";

      let iconElement = document.getElementById("accountMenuOpenCloseIcon");
      if (iconElement) {
        iconElement.classList.remove("lef-chevron-right");
        iconElement.classList.add("lef-chevron-down");
      }

      let overlayElement = document.getElementById("sideMenuOverlay");
      if (overlayElement) {
        overlayElement.classList.add("show-overlay");
      }

    } else {
      accountMenuPanel.style.display = "none";

      let iconElement = document.getElementById("accountMenuOpenCloseIcon");
      if (iconElement) {
        iconElement.classList.add("lef-chevron-right");
        iconElement.classList.remove("lef-chevron-down");
      }

      let overlayElement = document.getElementById("sideMenuOverlay");
      if (overlayElement) {
        overlayElement.classList.remove("show-overlay");
      }
    }
  }

  handleLinkClick(link: IMenuLink): void {
    this.linkClick.emit(link);

    if (link.jsAction) {
      this.executeJSAction(link.jsAction);
    }
  }

  private executeJSAction(jsAction: string): void {
    switch (jsAction) {
      case 'logout':
        this.logout();
        break;
      default:
        break;
    }
  }

  private logout() {
    this.loading.show();
    this.loginService.logout().subscribe(() => {
      this.trackingService.logOut.next(null);
      window.location.href = this.config.environment.contentSite;
    }, () => {
      this.router.navigateByUrl(`${this.config.webRoutes.root}`);
      this.loading.hide();
    });
  }

}
