import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PayPalCheckoutService } from 'src/app/payment/service/pay-pal-checkout-service.service';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IAutoShip } from '../../model/auto-ship';
import { IAutoShipDelivery } from '../../model/auto-ship-delivery';
import { AutoShipDialogService } from '../../service/auto-ship-dialog.service';
import { AutoShipService } from '../../service/auto-ship.service';

@Component({
  selector: 'lef-auto-ship',
  templateUrl: './auto-ship.component.html',
  styleUrls: ['./auto-ship.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoShipComponent implements OnInit, OnDestroy {
  @ViewChild('autoShipTabWrap', { read: TabStripComponent }) autoShipTabWrap: TabStripComponent;

  constructor(
    private readonly autoShipService: AutoShipService,
    private readonly dialogService: AutoShipDialogService,
    private readonly loadingService: LoadingService,
    private readonly payPalCheckOutService: PayPalCheckoutService,
    private readonly route: ActivatedRoute,
    private readonly config: CoreConfiguration,
    private readonly htmlUtilityService: HtmlUtilityService
  ) { }

  autoShip: IAutoShip;
  autoShipChangeSubscription: Subscription;

  get hasActiveDeliveries(): boolean {
    return !!this.autoShip.activeDeliveries.length;
  }

  get hasInactiveDeliveries(): boolean {
    return !!this.autoShip.inactiveDeliveries.length;
  }

  get hasSubscriptionsDeliveries(): boolean {
    return true;
  }

  public showActiveItems: boolean = null;
  public selectedTabIndex = 0;
  public tabs: any[] = [];
  public startOnSubTab: boolean = false;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.startOnSubTab = params?.tab === 'sub';
    });

    this.getAutoShip();
    this.subscribeToAutoShipChange();
    this.payPalCheckOutService.refreshPayPalInstanceForCapture(null);
  }

  ngOnDestroy(): void {
    this.unsubscribeToAutoShipChange();
  }

  getDeliveriesItemsCount(deliveries: IAutoShipDelivery[]) {
    let count = 0;

    if (deliveries?.length) {
      deliveries.forEach((delivery) => {
        if (delivery?.items?.length) {
          count += delivery?.items?.length;
        }
      });
    }

    return count;
  }

  updateUseRewardsOnVip(useRewardsOnVip: boolean) {
    this.autoShipService.updateRewardsUse(useRewardsOnVip).subscribe();
  }

  onTabSelect(tab: any) {
    if (!tab) {
      tab = this.adaGetActiveTabIndex();
    }

    if (tab?.index == null || tab?.index == undefined || tab.index == this.selectedTabIndex) {
      return;
    }

    this.selectedTabIndex = tab.index;
    this.adaUpdateTabIndex();
  }

  toggleActiveItems(showActiveItems: boolean) {
    this.showActiveItems = showActiveItems;
  }

  getItemButtonTitle(activeItems: boolean): string {
    const items = activeItems
      ? this.getDeliveriesItemsCount(this.autoShip?.activeDeliveries)
      : this.getDeliveriesItemsCount(this.autoShip?.inactiveDeliveries);

    const title = activeItems ? `Active Items ${items}` : `Inactive Items ${items}`;
    return title;
  }

  getAutoShip() {
    this.loadingService.show();
    this.autoShipService.getAutoShip(true).subscribe({
      next: (autoShip) => {
        this.setAutoShip(autoShip);
        this.loadingService.hide();
      },
      error: () => {
        this.loadingService.hide();
      },
    });
  }

  updateAutoShip(autoShipNumber: number) {
    this.autoShipService.updateAutoShip(this.autoShip, autoShipNumber).subscribe((autoShip) => {
      this.setAutoShip(autoShip);
    });
  }

  subscribeToAutoShipChange() {
    this.autoShipChangeSubscription = this.autoShipService.autoShipChange.subscribe((autoShip) => {
      this.setAutoShip(autoShip);
    });
  }

  unsubscribeToAutoShipChange() {
    if (this.autoShipChangeSubscription) {
      this.autoShipChangeSubscription.unsubscribe();
      this.autoShipChangeSubscription = null;
    }
  }

  setAutoShip(autoShip: IAutoShip) {
    if (autoShip?.activeDeliveries?.length) {
      autoShip.activeDeliveries.forEach(delivery => {
        delivery.deliveryIndex = autoShip.activeDeliveries.indexOf(delivery);
      });
    }

    this.autoShip = autoShip;
    this.setTabs();
    this.htmlUtilityService.restoreFocusPoint();
  }

  setTabs() {
    const tabs: any[] = [];
    const showItemsTab = !!this.autoShip.activeDeliveries.length || !!this.autoShip.inactiveDeliveries.length;
    const showSubscriptionsTab = this.config.environment.enablePremierSubscription && !!this.autoShip.subscriptionDeliveries.length;

    if (this.showActiveItems == null) {
      this.showActiveItems = this.hasActiveDeliveries;
    } else if (this.showActiveItems == false && !this.hasInactiveDeliveries) {
      this.showActiveItems = true;
    } else if (this.showActiveItems == true && !this.hasActiveDeliveries) {
      this.showActiveItems = false;
    }

    tabs.push({
      tab: 'items',
      title: 'AutoShip & Save',
      disabled: false,
    });

    if (showSubscriptionsTab) {
      tabs.push({
        tab: 'premier',
        title: 'Premier',
        disabled: false,
      });
    }

    if (this.selectedTabIndex === 0 && !showItemsTab) {
      this.selectedTabIndex = 1;
    }

    if (this.selectedTabIndex === 1 && !showSubscriptionsTab) {
      this.selectedTabIndex = 0;
    }

    if (showSubscriptionsTab && this.startOnSubTab) {
      this.selectedTabIndex = 1;
      this.startOnSubTab = false;
    }

    this.tabs = tabs;

    this.adaRemoveTabIndex();
    this.adaUpdateTabIndex();
  }

  openSchedule() {
    this.dialogService.openScheduleDialog(this.autoShip);
  }

  openLeDollarsDialog(templateRef: TemplateRef<any>) {
    this.dialogService.openLeDollarsDialog(templateRef);
  }

  private adaUpdateTabIndex() {
    setTimeout(() => {
      const tabs = (this.autoShipTabWrap as any)?.wrapper?.nativeElement.querySelectorAll('li[role="tab"]');
      for (const tab of tabs) {
        if (tab.getAttribute('aria-selected') === 'true') {
          tab.setAttribute('tabindex', '-1');
        } else {
          tab.setAttribute('tabindex', '0');
          tab.addEventListener('keypress', function (event) {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          });
        }
      }
    }, 500);
  }

  private adaRemoveTabIndex() {
    setTimeout(() => {
      for (const child of (this.autoShipTabWrap as any)?.wrapper?.nativeElement.children) {
        if (child.getAttribute('role') === 'tabpanel') {
          child.removeAttribute('tabindex');
        }
      }
    });
  }

  private adaGetActiveTabIndex() {
    const element = document.activeElement;
    if (!element || element.getAttribute('role') != 'tab' || !element.getAttribute('ng-reflect-index')) {
      return null;
    }

    return { index: +element.getAttribute('ng-reflect-index') };
  }
}
