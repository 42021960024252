<div *ngIf="loginTitle; else emptyTitle;" class="login-title">
  <span>{{loginTitle}}</span>
  <button class="closebtn" (click)="onCloseFlyout()"><i class="lef-close"></i></button>
</div>
<ng-template #emptyTitle>
  <div class="login-close">
    <button class="btn btn-link" (click)="onCloseFlyout()"><i class="lef-close"></i></button>
  </div>
</ng-template>
<div *ngIf="showLoginComponent" class="mb-4">
  <label id="loginEmail" *ngIf="showWelcomeMessage && (!loginModel || !loginModel.email)" class="login-welcome">
    <strong class="zilla">{{welcomeMessage}}</strong>
  </label>
  <div class="email-form" *ngIf="showEmailForm">
    <div *ngIf="!isGuestCheckoutFlow && isCheckoutFlow && showContinueAsGuestButton">
      <button (click)="isGuestCheckoutFlow=true" type="button" class="btn btn-white btn-link form-control">Checkout
        as a Guest</button>
      <div class="centered-hr">OR</div>
    </div>
    <ng-container *ngIf="!isGuestCheckoutFlow; else guestHeader;">
      <p class="d-flex justify-content-center"><strong class="text-primary">Sign In or Create an account</strong>
      </p>
      <p class="font-md">
        Enter your email to get started. If you already have
        an account, we’ll find it for you.
      </p>
    </ng-container>
    <ng-template #guestHeader>
      <p class="zilla text-primary font-lg font-weight-medium">Welcome!</p>
      <p class="font-md">
        Enter your email to checkout as a guest.
      </p>
    </ng-template>

    <form class="k-form w-100" [formGroup]="emailForm" (ngSubmit)="submitEmailForm()">
      <div>
        <div class="login-email mb-3">
          <input kendoTextBox lefSetFocus type="text" class="form-control w-100" [attr.placeholder]="placeHolder"
                 formControlName="email" aria-label="Email Address" />
          <p *ngIf="isResellerFlow" role="alert">Your account email address must match the owner's email address.</p>
        </div>
        <div class="login-actions">
          <button *ngIf="!isGuestCheckoutFlow" class="btn btn-link" role="link">Need help signing in?</button>
          <button type="submit" class="btn btn-action" [disabled]="!submitEnabled">Submit</button>
        </div>
        <div class="policy-content">
          By clicking Submit, you affirm you have carefully read, understand, and agree to be
          bound by and accept our <a [href]="config.legal.termsAndConditions" target="_blank">General Terms &
            Conditions</a> and <a [href]="config.legal.privacyPolicy" target="_blank">Global Privacy Policy</a>,
          including the class action waiver and arbitration provision therein.
        </div>
        <div *ngIf="isGuestCheckoutFlow">
          <span class="centered-hr">OR</span>
          <button type="button" class="btn btn-white btn-link form-control col-12" (click)="signUp()">Create an
            account</button>
        </div>
      </div>
      <div *ngIf="showFormErrorMessage && errorMessage">
        <p class="font-sm text-red" role="alert">{{errorMessage}}</p>
      </div>
      <div *ngIf="showFormErrorMessage && !emailForm.valid && emailForm.controls.email.errors?.notNumber">
        <p class="font-sm ccmt-1" role="alert">If you don't know the email address asociated with your account, please
          contact support at<br />1-800-678-8989.</p>
      </div>
    </form>
  </div>
  <div class="password-form" *ngIf="showPasswordForm">
    <p class="zilla text-primary font-lg font-weight-medium mb-3">Welcome back!</p>
    <div class="alert alert-info" *ngIf="isGuestCheckoutFlow">
      <p class="font-sm m-0">
        <i class="lef-info-hex"></i>
        <span>The submitted email address is associated with an
          existing account. If you wish to proceed as a guest,
          please use a different email address.</span>
      </p>
    </div>
    <div class="customer-email">
      <p>
        <span *ngIf="loginModel?.email"><strong>{{label}}</strong></span>
        <span *ngIf="loginModel?.email"> {{loginModel.email}}</span><br />
        <span *ngIf="showResetOption">
          <button (click)="resetForms()" type="button" class="btn btn-link">Use a different email?</button>
        </span>
      </p>
    </div>
    <p><strong class="text-primary">Enter your password</strong></p>
    <form class="k-form" [formGroup]="passwordForm" (ngSubmit)="submitLoginForm()">
      <div class="sc-login-box">
        <div class="pass-container">
          <input kendoTextBox lefSetFocus type="password" class="form-control login-email" placeholder="Password"
                 [type]="showLoginPassword ? 'text' : 'password'" formControlName="password"
                 aria-label="enter password" />

          <button type="button" class="hide-show-btn" (click)="toggleLoginPasswordVisibility()">{{showLoginPassword ?
            'Hide'
            :
            'Show'}}</button>
        </div>
      </div>
      <div *ngIf="showFormErrorMessage" class="mt-2">
        <p class="font-sm text-red" role="alert">{{errorMessage}}</p>
      </div>
      <div class="mt-2 mb-2 d-flex flex-nowrap justify-content-between">
        <button type="button" (click)="showForgotPasswordDialog()" aria-haspopup="dialog" class="btn btn-link"
                tabindex="0">
          {{forgotPasswordLabel}}</button>

        <button type="submit" class="btn btn-action sc-login-btn"
                [disabled]="!passwordForm.valid || !submitEnabled">Sign In</button>
      </div>
    </form>
  </div>
  <div class="new-password-form" *ngIf="showNewPasswordForm">
    <p class="zilla text-primary font-lg font-weight-medium">Welcome!</p>
    <form class="k-form" [formGroup]="newPasswordForm" (ngSubmit)="submitNewPasswordForm()">
      <div>
        <p class="font-md mb-3">You are one step closer to becoming a Life Extension member.</p>
        <div class="customer-email mb-3">
          <p>
            <span *ngIf="loginModel?.email"><strong class="text-primary">{{label}}</strong></span>
            <span *ngIf="loginModel?.email"> {{loginModel.email}}</span><br />
            <span *ngIf="showResetOption">
              <button (click)="resetForms()" type="button" class="btn btn-link">Use a different email?</button>
            </span>
          </p>
        </div>
        <p class="text-primary"><strong>Create a password</strong></p>
        <div class="pass-container mb-2">
          <input kendoTextBox lefSetFocus type="password" class="form-control mb-2" placeholder="Create a Password*"
                 aria-label="Create a Password" formControlName="password"
                 [type]="showRegistrationPassword ? 'text' : 'password'" />
          <button type="button" class="hide-show-btn"
                  (click)="toggleRegistrationPasswordVisibility()">{{showRegistrationPassword ?
            'Hide' :
            'Show'}}</button>
        </div>
        <div
             *ngIf="newPasswordForm.controls.password.hasError('passwordStrength') && newPasswordForm.controls.password.touched">
          <p class="font-sm text-red mb-1" role="alert">{{ ValidationMessages.PasswordStrengthMessage }}</p>
        </div>
        <div
             *ngIf="newPasswordForm.controls.password.hasError('passwordMinLength') && newPasswordForm.controls.password.touched">
          <p class="font-sm text-red" role="alert">{{ ValidationMessages.PasswordMinLengthMessage }}</p>
        </div>

        <div class="pass-container">
          <input kendoTextBox type="password" class="form-control mb-2" placeholder="Retype Password*"
                 aria-label="Retype password" formControlName="passwordConfirmation"
                 [type]="showConfirmPassword ? 'text' : 'password'" />
          <button type="button" class="hide-show-btn" (click)="toggleConfirmPasswordVisibility()">{{showConfirmPassword
            ?
            'Hide' :
            'Show'}}</button>
        </div>

        <div
             *ngIf="newPasswordForm.controls.passwordConfirmation.hasError('passwordMismatch') && newPasswordForm.controls.passwordConfirmation.touched">
          <p class="font-sm text-red" role="alert">{{ ValidationMessages.PasswordMismatchMessage }}</p>
        </div>
        <div *ngIf="showFormErrorMessage">
          <p class="font-sm text-red" role="alert">{{errorMessage}}</p>
        </div>
        <div class="text-right mb-3">
          <button type="submit" class="btn btn-action" [disabled]="!newPasswordForm.valid  || !submitEnabled">Create an
            Account</button>
        </div>
      </div>
    </form>
  </div>
  <div class="zip-form" *ngIf="showZipVerificationForm">
    <form class="k-form" [formGroup]="zipVerificationForm" (ngSubmit)="submitZipForm()">
      <div *ngIf="!showFormErrorMessage" class="mb-3">
        <label id="loginZipCode" role="alert">For Account Verification please enter your billing zip code.</label>
      </div>
      <div class="login-zip-verify">
        <input kendoTextBox lefSetFocus type="text" class="form-control" placeholder="Zip Code"
               formControlName="zipCode" aria-labelledby="loginZipCode" />
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-action"
                  [disabled]="!zipVerificationForm.valid || !submitEnabled">Submit</button>
        </div>
      </div>
      <div *ngIf="showFormErrorMessage">
        <p class="font-sm text-red" role="alert">{{errorMessage}}</p>
      </div>
    </form>
  </div>
  <div class="country-form" *ngIf="showCountryVerificationForm">
    <form class="k-form" [formGroup]="countryVerificationForm" (ngSubmit)="submitCountryForm()">
      <div *ngIf="!showFormErrorMessage" class="mb-1">
        <p role="font-sm alert">For Account Verification please select your billing country.</p>
      </div>
      <div>
        <select formControlName="country" name="country" class="form-control mb-3">
          <option [ngValue]="null">Select*</option>
          <ng-container *ngFor="let opt of countries">
            <option [ngValue]="opt.code">{{ opt.description }}</option>
          </ng-container>
        </select>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-action"
                  [disabled]="!countryVerificationForm.valid|| !submitEnabled">Submit</button>
        </div>
      </div>
      <div *ngIf="showFormErrorMessage" class="mt-1">
        <p class="font-sm text-red" role="alert">{{errorMessage}}</p>
      </div>
    </form>
  </div>
</div>

<lef-forgot-password *ngIf="showForgotPasswordComponent" (closeDialog)="onCloseFlyout()"></lef-forgot-password>
<lef-reset-password *ngIf="showResetPasswordComponent" [recoveryId]="recoveryId"
                    (closeDialog)="onCloseFlyout()"></lef-reset-password>
<lef-verify-account-code *ngIf="showVerifyAccountCodeComponent" [verificationModel]="verificationModel"
                         (accountVerified)="processAccountVerified($event)"
                         (closeDialog)="setCurrentComponent('showLoginComponent')"></lef-verify-account-code>
<lef-registration *ngIf="showRegistrationComponent" [model]="registrationModel"
                  (registrationComplete)="getCurrentUser(true)" (closeDialog)="onCloseFlyout()"></lef-registration>

<div *ngIf="!showPasswordForm && !showVerifyAccountCodeComponent && !showRegistrationComponent && !showResetPasswordComponent"
     class="login-bullets-wrap">
  <p><strong class="text-primary">Create a Life Extension account today!</strong></p>
  <ul class="login-bullets">
    <li><i class="lef-ui-content-delivery"></i> <span class="blttext">View and manage your Order History</span></li>
    <li><i class="lef-money-sales-tag"></i> <span class="blttext">Acquire LE Dollars off of purchase</span></li>
    <li><i class="lef-as-icon"><span class="lef-two_toned_autoship-and-save"><span class="path1"></span><span
                class="path2"></span><span class="path3"></span><span class="path4"></span><span
                class="path5"></span><span class="path6"></span><span class="path7"></span><span
                class="path8"></span></span></i><span class="blttext">Subscribe and Save on
        essentials</span></li>
    <li><i class="lef-cart-custom-new"></i> <span class="blttext">Easy Check Out process</span></li>
  </ul>
</div>