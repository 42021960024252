
export interface ISourceInfo {
    url: string;
    source: string;
    sourcePlacement: string;
    sourceIndex: string;
}

export class SourceInfo implements ISourceInfo {
    url: string;
    source: string;
    sourcePlacement: string;
    sourceIndex: string;

    constructor(source: string, sourcePlacement: string, sourceIndex: string) {
        this.url = window.location.href;
        this.source = source;
        this.sourcePlacement = sourcePlacement;
        this.sourceIndex = sourceIndex;
    }
}