import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { PaymentModule } from '../payment/payment.module';
import { SharedModule } from '../shared/shared.module';
import { ShippingModule } from '../shipping/shipping.module';
import { LoginModule } from './../login/login.module';
import { CartComponent, CartItemsComponent, CartOrderSummaryComponent, CartShippingComponent } from './component';
import { CartPremierOfferComponent } from './component/cart-premier-offer/cart-premier-offer.component';

const modules = [CommonModule, RouterModule, SharedModule, CommonsModule, CartCommonModule, ShippingModule, PaymentModule, LoginModule];

const components = [CartComponent, CartOrderSummaryComponent, CartItemsComponent, CartShippingComponent];
@NgModule({
    imports: [modules],
    declarations: [components, CartPremierOfferComponent]
})
export class CartModule { }
