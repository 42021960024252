import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ISourceInfo, SourceInfo } from 'src/app/cart-common/model/source-info';
import { CartService } from 'src/app/cart/service';
import { FloatingCartService } from 'src/app/common/service/floating-cart.service';
import { IOrderItem } from 'src/app/order-by-item/model/order-item';
import { CartNotificationService } from 'src/app/shared/service/cart-notification.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { BaseOrderDetails } from '../../model/base-order-details.model';
import { OrderDetail } from '../../model/order-detail.model';

@Component({
  selector: 'lef-order-history-order-detail',
  templateUrl: './order-history-order-detail.component.html',
  styleUrls: ['./order-history-order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderHistoryOrderDetailComponent extends BaseOrderDetails implements OnInit {
  @Input() order: OrderDetail;
  @Output() goToOrderDetails: EventEmitter<string> = new EventEmitter();
  @Output() goToReturns: EventEmitter<string> = new EventEmitter();

  private sourceInfo: ISourceInfo = new SourceInfo('Order Detail', '', '');

  constructor(
    router: Router,
    floatingCartService: FloatingCartService,
    cartService: CartService,
    trackingService: TrackingService,
    notificationService: CartNotificationService,
    loadingService: LoadingService,
    dialogService: DialogService
  ) {
    super(router, floatingCartService, cartService, trackingService, notificationService, loadingService, dialogService);
  }

  ngOnInit(): void { }

  returnItems() {
    this.goToReturns.emit(this.order.orderNumber);
  }
  goToDetails() {
    this.goToOrderDetails.emit(this.order.orderNumber);
  }

  openOrderDetails(orderNumber: string) {
    this.goToOrderDetails.emit(orderNumber);
  }

  addAllItemsToCart() {
    const items: IOrderItem[] = [];
    this.order.shipments.forEach((shipment) => {
      shipment.items.forEach((item) => {
        items.push({
          itemNumber: item.itemNumber,
          quantity: 1,
          sourceInfo: this.sourceInfo
        });
      });
    });
    this.addToCart(items);
  }
}
