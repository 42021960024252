import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoginService } from 'src/app/login/service/login.service';
import { CaptchaTriggerService } from 'src/app/security/service/captcha-trigger.service';
import { ActionService } from 'src/app/shared/service/action.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { Constants } from 'src/app/tracking/model/constants';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { RefreshService } from '../service/refresh.service';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  private readonly actionTokenHeader: string = 'LeCartIntent';

  constructor(
    private readonly captchaTrigger: CaptchaTriggerService,
    private readonly config: CoreConfiguration,
    private readonly tracking: TrackingService,
    private readonly refreshService: RefreshService,
    private readonly commonService: CommonService,
    private readonly actionService: ActionService,
    private readonly router: Router,
    private readonly loginService: LoginService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {
    if (request.url.startsWith(this.config.environment.contentSite)) {
      return next.handle(request);
    }

    request = request.clone({
      withCredentials: true,
      headers: this.getFinalHeaders(request.headers),
    });

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.updateRefreshData(event);
          this.updateTrackingData(event);
          this.refreshGlobalMessages(event);
        }
      }),
      catchError((error: HttpErrorResponse): any => {
        if (!error) {
          return;
        }

        switch (error.status) {
          case 0:
            break;
          case 412:
            this.captchaTrigger.triggerVerification();
            break;
          case 307:
            this.goToRedirectionUrl(error);
            break;
          case 401:
            this.goToLogin();
            break;
          default:
            if (error.status >= 500) {
              this.goToErrorPage();
            }
        }

        return throwError(error);
      })
    );
  }

  refreshGlobalMessages(response: HttpResponse<any>) {
    const refreshGlobalMessages = response?.body?.refreshGlobalMessages;
    if (refreshGlobalMessages) {
      if (refreshGlobalMessages === true) {
        this.commonService.refreshGlobalMessages();
      }
    }
  }

  updateTrackingData(response: HttpResponse<any>) {
    const trackingValues = response.headers.get(Constants.TrackingHeaderName);
    if (trackingValues) {
      this.tracking.updateUserData(trackingValues);
    }
  }

  updateRefreshData(response: HttpResponse<any>) {
    const appVersion = response.headers.get(this.refreshService.appVersionHeader);
    if (appVersion) {
      this.refreshService.updateRefreshData(appVersion);
    }
  }

  goToRedirectionUrl(error: HttpErrorResponse) {
    const target = error?.error?.url;
    window.location.href = target ? target : this.config.webRoutes.root;
  }

  goToErrorPage() {
    window.location.href = '/error';
  }

  goToLogin(): void {
    if (window.location.pathname === this.config.webRoutes.checkout) {
      return;
    }

    if (window.location.pathname === this.config.webRoutes.login) {
      return;
    }

    if (!this.loginService.isLoginComponentOpen) {
      const returnUrl = window.location.pathname + window.location.search;
      this.router.navigateByUrl(`${this.config.webRoutes.root}?returnUrl=${returnUrl}`);
    }
  }

  private getFinalHeaders(headers: HttpHeaders): HttpHeaders {
    headers = this.addActionToken(headers);
    return this.addHeadersFromQueryString(headers).set('CartRoute', window.location.href).set('ClientReferer', document.referrer);
  }

  private addHeadersFromQueryString(headers: HttpHeaders): HttpHeaders {
    const queryParams = this.getQueryParams();

    Object.keys(this.config.queryStringHeadersMap).forEach((k: string) => {
      let key: string = this.config.queryStringHeadersMap[k];
      let headerAdded = false;

      if (queryParams.hasOwnProperty(key)) {
        const value: string = queryParams[key];
        if (value) {
          headers = headers.set(key, value);
          headerAdded = true;
        }
      }

      // Check again for lowercase query string values
      if (!headerAdded && queryParams.hasOwnProperty(key.toLowerCase())) {
        const value: string = queryParams[key.toLowerCase()];
        if (value) {
          headers = headers.set(key, value);
        }
      }
    });
    return headers;
  }

  private addActionToken(headers: HttpHeaders) {
    const actionToken = this.actionService.GetCurrentIntent();
    if (!!actionToken) {
      headers = headers.set(this.actionTokenHeader, actionToken);
    }

    return headers;
  }

  private getQueryParams(): { [key: string]: string } {
    if (location.search.length < 1) {
      return {};
    }
    return Object.assign(
      {},
      ...location.search
        .substring(1, location.search.length)
        .split('&')
        .map((x) => {
          const kvp = x.split('=');
          return { [kvp[0]]: decodeURIComponent(kvp[1]) };
        })
    );
  }
}
