import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ICartItemPriceBreak } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { IAutoShipItem } from '../../model/auto-ship-item';

@Component({
  selector: 'lef-auto-ship-add-to-cart',
  templateUrl: './auto-ship-add-to-cart.component.html',
  styleUrls: ['./auto-ship-add-to-cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoShipAddToCartComponent implements OnInit {

  @Input() item: IAutoShipItem;
  @Input() sourcePlacement: string = '';
  @Input() sourceIndex: string = '';

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  basePriceBreak: ICartItemPriceBreak = null;

  quantity: number;
  basePrice: number;
  price: number;
  percentageSavings: number;

  get itemImageUrl(): string {
    return this.item && this.item.itemNumber
      ? this.config.environment.itemUrlTemplates.itemImageUrl.replace('{itemNumber}', this.item.itemNumber)
      : '';
  }

  constructor(
    private readonly config: CoreConfiguration,
    private readonly router: Router,
    private readonly htmlUtilityService: HtmlUtilityService
  ) { }

  ngOnInit() {
    this.calculatePrice();
  }

  updateQuantity(newQuantity) {
    this.quantity = newQuantity;
    this.calculatePrice();
    this.htmlUtilityService.restoreFocusPoint();
  }

  addToCart() {
    this.closeDialog.emit();
    this.router.navigate([''], { queryParams: { item: this.item.itemNumber, qty: this.quantity, s: 'AutoShip', sp: this.sourcePlacement, si: this.sourceIndex } });
  }

  calculatePrice() {
    if (!this.quantity) { this.quantity = 1; }
    if (!this.basePriceBreak) { this.basePriceBreak = this.item.priceBreakList.find(p => p.quantityBreak === 1); }

    const priceBreaks = this.item.priceBreakList.filter(p => p.quantityBreak <= this.quantity);
    this.price = priceBreaks[priceBreaks.length - 1].price * this.quantity;
    this.basePrice = this.basePriceBreak.price * this.quantity;
    this.calculateSavings();
  }

  calculateSavings() {
    this.percentageSavings = (this.basePrice - this.price) * 100 / this.basePrice;
  }
}
