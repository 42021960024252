import { Injectable } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IListItem } from 'src/app/shared/model/list-item';

@Injectable({
  providedIn: 'root',
})
export class AutoShipValuesService {
  private adjustedStartIndex = 0;

  public useAdjustedDates = false;

  public autoShipFrequencyValues: Array<{ value: number; text: string }> = [
    { value: 1, text: '1 Month' },
    { value: 2, text: '2 Months' },
    { value: 3, text: '3 Months' },
    { value: 4, text: '4 Months' },
    { value: 6, text: '6 Months' },
    { value: 9, text: '9 Months' },
    { value: 12, text: '12 Months' }
  ];

  public autoShipFrequencyFullValues: Array<{ value: number; text: string }> = [
    { value: 1, text: '1 Month' },
    { value: 2, text: '2 Months' },
    { value: 3, text: '3 Months' },
    { value: 4, text: '4 Months' },
    { value: 5, text: '5 Months' },
    { value: 6, text: '6 Months' },
    { value: 7, text: '7 Months' },
    { value: 8, text: '8 Months' },
    { value: 9, text: '9 Months' },
    { value: 10, text: '10 Months' },
    { value: 11, text: '11 Months' },
    { value: 12, text: '12 Months' }
  ];

  public autoShipStartDates: Array<{ value: string; text: string }> = [];
  public adjustedAutoShipStartDates: Array<{ value: string; text: string }> = [];

  constructor(private readonly config: CoreConfiguration) {
    this.setAutoShipStartDates();
  }

  setAutoShipStartDates() {
    for (let month = 1; month <= 12; month++) {
      const date = this.addMonth(new Date(), month);
      this.autoShipStartDates.push({
        value: date.toDateString(),
        text: date.toLocaleDateString('en-us', { month: 'long', day: 'numeric' }),
      });
    }
  }

  setAdjustedAutoShipStartDates(adjustedDate: number) {
    this.adjustedAutoShipStartDates = [];
    const adjustedDates: Array<Date> = [];
    let now = new Date();
    let monthsAmount = 12;

    if (adjustedDate > now.getDate()) {
      now.setDate(adjustedDate);
      if (now.getDate() !== adjustedDate) {
        // Last day of previous month.
        now.setDate(0);
      }
      adjustedDates.push(now);
      this.adjustedAutoShipStartDates.push({
        value: now.toDateString(),
        text: now.toLocaleDateString('en-us', { month: 'long', day: 'numeric' }),
      });
      now = new Date();
      monthsAmount--;
    }


    for (let month = 1; month <= monthsAmount; month++) {
      const date = this.addMonth(new Date(), month);
      date.setDate(adjustedDate);
      if (date.getDate() !== adjustedDate) {
        // Last day of previous month.
        date.setDate(0);
      }
      adjustedDates.push(date);
      this.adjustedAutoShipStartDates.push({
        value: date.toDateString(),
        text: date.toLocaleDateString('en-us', { month: 'long', day: 'numeric' }),
      });
    }

    const daysDiff = (adjustedDates[0].getTime() - now.getTime()) / (1000 * 3600 * 24);
    if (daysDiff <= this.config.environment.autoShipDaysThreshold) {
      this.adjustedStartIndex = 1;
    }
  }

  getAutoShipStartDates(): Array<{ value: string; text: string }> {
    return this.useAdjustedDates ? this.adjustedAutoShipStartDates : this.autoShipStartDates;
  }

  getAutoShipStartDateIndex(): number {
    return this.useAdjustedDates ? this.adjustedStartIndex : 0;
  }

  getShippingDayOfMonth(dayOfMonth: number, includeMonthText = false): string {
    let day;
    switch (dayOfMonth) {
      case 1:
      case 21:
      case 31:
        day = dayOfMonth + 'st';
        break;
      case 2:
      case 22:
        day = dayOfMonth + 'nd';
        break;
      case 3:
      case 23:
        day = dayOfMonth + 'rd';
        break;
      default:
        day = dayOfMonth + 'th';
        break;
    }
    return includeMonthText ? `${day} of the Month` : day;
  }

  public getShipDayOptions(): IListItem[] {
    const days: IListItem[] = Array.from(Array(32).keys())
      .slice(1)
      .map((i) => {
        return { value: i, description: this.getShippingDayOfMonth(i) };
      });

    return days;
  }

  private addMonth(date: Date, month: number) {
    const day = date.getDate();
    date.setMonth(date.getMonth() + month);
    // Prevent roll overs.
    if (date.getDate() !== day) {
      // Last day of previous month.
      date.setDate(0);
    }
    return date;
  }
}
