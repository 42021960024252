<div class="order-history">
  <lef-section-title [title]="'My Orders'"></lef-section-title>
  <div class="filters">
    <div class="time-criteria mb-2 mb-md-0">
      <label class="m-0" id="orderPlacedIn">Orders Placed in:</label>
      <!-- <kendo-dropdownlist [data]="orderSearchPeriod" [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                          name="range" [(ngModel)]="daysRange" (ngModelChange)="search()">
      </kendo-dropdownlist> -->
      <select name="frequency" [(ngModel)]="daysRange" (ngModelChange)="search()" aria-labelledby="orderPlacedIn">
        <ng-container *ngFor="let opt of orderSearchPeriod">
          <option [ngValue]="opt.value">{{ opt.text }}</option>
        </ng-container>
      </select>
    </div>
    <div class="search-criteria">
      <label class="m-0" id="searchOrders">Search Orders:</label>
      <kendo-textbox lefSetChildAttributes [childAttributes]="['input:aria-labelledby=searchOrders']"
                     placeholder="Item name, order# or item#" [clearButton]="true" [ngModel]="currentCriteria"
                     aria-labelledby="searchOrders" (valueChange)="onChange($event)"
                     (keydown.enter)="onEnterPress($event)" type="text">
        <ng-template kendoTextBoxSuffixTemplate>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" [icon]="'search'" (click)="search()"></button>
        </ng-template>
      </kendo-textbox>
    </div>
  </div>
  <lef-cart-messages *ngIf="messages" [messages]="messages"></lef-cart-messages>
  <div *ngIf="notResultsFound">No records found!</div>
  <div class="order-history-wrap">
    <lef-order-history-order-detail *ngFor="let order of orders" [order]="order"
                                    (goToOrderDetails)="openOrderDetails($event)" (goToReturns)="returnItems($event)">
    </lef-order-history-order-detail>
  </div>
  <ng-container *ngFor="let item of items, let itemIndex = index">
    <lef-order-item [item]="item" [itemIndex]="itemIndex" (goToOrderDetails)="openOrderDetails($event)">
    </lef-order-item>
  </ng-container>
  <div *ngIf="hasMore" class="d-flex justify-content-center">
    <button type="button" class="btn btn-warning" (click)="loadMoreData()">Show More</button>
  </div>
</div>