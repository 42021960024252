import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { RequestResetPasswordModel } from '../../model/password-request-reset.model';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  forgotPasswordModel: RequestResetPasswordModel;
  forgotPasswordForm: UntypedFormGroup;
  forgotPasswordFormFields: FormlyFieldConfig[];
  forgotDialogSubmitted = false;

  constructor(private readonly loginService: LoginService,
    private readonly loadingService: LoadingService) { }

  ngOnInit() {
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm() {
    this.forgotDialogSubmitted = false;

    this.forgotPasswordModel = new RequestResetPasswordModel();
    this.forgotPasswordForm = new UntypedFormGroup({});
    this.forgotPasswordFormFields = [
      {
        key: 'email',
        type: 'focusInput',
        className: '',
        templateOptions: {
          label: '',
          placeholder: 'Email Address',
        },
        validators: {
          validation: [Validators.required, Validators.email],
        },
      },
    ];
  }

  requestResetPassword() {
    this.loadingService.show();
    this.loginService.requestResetPassword(this.forgotPasswordModel).subscribe((test) => {
      this.forgotDialogSubmitted = true;
      this.loadingService.hide();
    });
  }

  close() {
    this.closeDialog.emit();
    this.initForgotPasswordForm();
  }
}
