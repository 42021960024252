import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CoreConfiguration {
  static rootConfig = {
    apiServer: env.api,
  };
  environment = env;
  runtimeAppVersion = '';

  apiRoutes = {
    account: 'api/account',
    autoShip: 'api/autoShip',
    captcha: 'api/captcha',
    cart: 'api/cart',
    checkout: 'api/checkout',
    common: 'api/common',
    contactPreferences: 'api/contactPreferences',
    labTest: 'api/labTest',
    minorConsent: 'api/minorConsent',
    login: 'api/login',
    orderByNumber: 'api/orderByNumber',
    orderHistory: 'api/orderHistory',
    paymentOptions: 'api/paymentOption',
    resellerApplication: 'api/resellerApplication',
    paymentManagement: 'api/paymentManagement',
    registration: 'api/registration',
    renderingContent: 'api/renderingContent',
    region: 'api/region',
    returns: 'api/returns',
    shipping: 'api/shipping',
    favorite: 'api/favorites',
    genesys: 'api/genesys',
  };

  contentSiteApiRoutes = {
    tracking: '/api/User',
  };

  webRoutes = {
    root: '/cart',
    accountContactPreferences: '/account/contactpreferences',
    accountProfileSettings: '/account/accountsettings',
    accountShippingAddress: '/account/shippingaddresses',
    accountPaymentManagement: '/account/paymentmanagement',
    accountOrderHistory: '/account/orderhistory',
    accountMyWishLists: '/account/favorites',
    accountLabTesting: '/account/bloodlab',
    accountAutoShip: '/account/autoship',
    accountInvoicePreferences: '/account/invoicepreferences',
    accountHome: '/account',
    checkout: '/checkout',
    login: '/login',
    logout: '/login/logout',
    officerInfo: '/account/officerinfo',
    orderByNumber: '/orderbynumber',
    myLifeExtension: env.myOffers.myLifeExtensionPageUrl,
    myLifeExtensionWholesale: env.myOffers.myLifeExtensionWholesalePageUrl,
  };

  maximumAllowedToAddByNumber = 20;

  coveoConfiguration = {
    organization: env.coveoConfiguration.organization,
    apiKey: env.coveoConfiguration.apiKey,
    restApiUrl: env.coveoConfiguration.restApiUrl,
    analyticsApiUrl: env.coveoConfiguration.analyticsApiUrl,
    loggerLevel: env.coveoConfiguration.loggerLevel,
    searchPage: `${env.contentSite}/search`,
    searchHub: env.coveoConfiguration.searchHub,
    queryExpressions: [
      {
        field: '@templatedisplayname',
        operator: '==',
        values: ['Product', 'Third Party Product'],
        isNotEqualExpression: false,
      },
      {
        field: '@source',
        operator: '==',
        values: [env.coveoConfiguration.source],
        isNotEqualExpression: false,
      },
    ],
  };

  images = {
    placeHolder: {
      small: 'assets/images/ph-sm.png',
    },
  };

  cookies = {
    cart: 'AC',
    visitor: 'LEV',
    genesysQueue: 'gen_q',
    genesysChat: 'gen_c',
    genesysVisibility: 'gen_v',
    genesysActive: 'gen_a',
    lifeExtensionUser: 'LE_U',
    lifeExtensionSupplementQuiz: 'LE_LSQ',
  };

  cartCookieKeys = {
    customerNumber: 'AC_CNBR',
    customerType: 'AC_MT',
    nameToken: 'AC_MN',
    loginStatus: 'AC_LS',
    wholesalerType: 'AC_WT',
    email: 'AC_CE',
    firstName: 'AC_FN',
    lastName: 'AC_LN',
    date: 'AC_ME',
    itemsCount: 'AC_ITS_CART',
  };

  queryStringHeadersMap = {
    customerTracking: 'CT',
    batchDate: 'BD',
    customerId: 'CID',
    trackingSourceCode: 'ctsourceCode',
    sourceCode: 'sourcecode',
    cjEvent: 'cjevent',
    prospect: 'p',
    emailId: 'eid',
    marketingCode: 'mc',
  };

  queryStringMap = {
    item: 'item',
    qty: 'qty',
    checkout: 'checkout',
    features: 'features',
    autoship: 'autoship',
    sourceCode: 'sourcecode',
    source: 's',
    sourcePlacement: 'sp',
    sourceIndex: 'si',
  };

  sourceTypeMap = {
    carousel: 'C',
    grid: 'G',
    list: 'L',
    blog: 'B',
    dialog: 'D',
    featuredProduct: 'FP'

  };

  refreshConfiguration = {
    refreshOnNavigation: env.refreshConfiguration.refreshOnNavigation,
    refreshOnIdle: env.refreshConfiguration.refreshOnIdle,
    refreshTimeout: env.refreshConfiguration.refreshTimeout,
  };

  oneTrustGroups = {
    mandatory: 'C0001',
    performance: 'C0002',
    functional: 'C0003',
    targeting: 'C0004',
    socialMedia: 'C0005',
  };

  genesysConfiguration = {
    enable: env.genesysConfiguration.enable,
    urlSendChat: env.genesysConfiguration.urlSendChat,
    workingDays: env.genesysConfiguration.workingDays,
    startTime: env.genesysConfiguration.startTime,
    endTime: env.genesysConfiguration.endTime,
    wellnessId: env.genesysConfiguration.wellnessId,
    serviceId: env.genesysConfiguration.serviceId,
  };

  googlepayConfiguration = {
    environment: env.googlepayConfiguration.environment,
    merchantId: env.googlepayConfiguration.merchantId,
  };

  emptyGuid = '00000000-0000-0000-0000-000000000000';

  reselleracknowledgementEnabled = env.reselleracknowledgementEnabled;

  legal = {
    termsAndConditions: env.legal.termsAndConditions,
    privacyPolicy: env.legal.privacyPolicy
  };
}
