import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private readonly loginService: LoginService,
    private readonly loading: LoadingService,
    private readonly router: Router,
    private readonly trackingService: TrackingService,
    public readonly config: CoreConfiguration) { }

  ngOnInit() {
    this.logout();
  }

  logout() {
    this.loading.show();
    this.loginService.logout().subscribe(() => {
      this.trackingService.logOut.next(null);
      window.location.href = this.config.environment.contentSite;
    }, () => {
      this.router.navigateByUrl(`${this.config.webRoutes.root}`);
      this.loading.hide();
    });
  }

}
