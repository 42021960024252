import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICartItem } from 'src/app/cart-common/model';
import { ISourceInfo, SourceInfo } from 'src/app/cart-common/model/source-info';
import { CartService } from 'src/app/cart/service';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { FavoriteModel, IFavoriteItem } from '../../model/favorite.model';
import { FavoriteDialogService } from '../../service/favorite-dialog.service';
import { IFavorite } from './../../model/favorite.model';
import { IWishList, WishListModel } from './../../model/wish.model';
import { FavoriteService } from './../../service/favorite.service';

@Component({
  selector: 'lef-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoritesComponent implements OnInit {
  @ViewChild('messages', { read: ElementRef, static: false }) private messagesBox: ElementRef;
  originalFavorite: IFavorite;
  favorite: IFavorite = new FavoriteModel();
  wishList: IWishList[];
  selectedItem: IWishList;

  screenSizes = ScreenSizes;
  defaultItem: IWishList = { wishListId: null, wishListName: 'Change List', disable: false, isDefaultWishList: false };
  pageSize: number = 10;

  private sourceInfo: ISourceInfo = null;

  get itemUrlTemplate(): string {
    return this.baseConfig.environment.itemUrlTemplates.itemUrlTemplate;
  }

  constructor(
    private readonly config: CoreConfiguration,
    private readonly favoriteService: FavoriteService,
    private readonly favoriteDialogService: FavoriteDialogService,
    private readonly loadingService: LoadingService,
    public readonly responsiveService: ResponsiveService,
    private readonly cartService: CartService,
    private readonly baseConfig: CoreConfiguration
  ) { }

  ngOnInit(): void {
    this.init();
    this.responsiveService.checkCurrentSize();
  }

  init() {
    this.getWishListAll();
  }

  setSourceInfo() {
    this.sourceInfo = new SourceInfo('Wish List', `${this.config.sourceTypeMap.grid}1`, '');
  }

  getWishListAll() {
    this.loadingService.show('Loading My Wish Lists...');
    this.favoriteService.getFavoriteItemListAll().subscribe(
      (response) => {
        this.setResponse(response);
        this.selectedItem = this.wishList[0];
        this.filterWishList(this.selectedItem);
        this.loadingService.hide();
      },
      (error: any) => {
        this.loadingService.hide();
      }
    );
  }

  filterWishList(wishListItem: IWishList) {
    this.favorite.wishListFilter = wishListItem.wishListId;
    this.applyWishListFilter(wishListItem);
    this.isDefaultWishList();
  }

  applyWishListFilter(wishListItem: IWishList) {
    this.favorite.favoriteItems = this.originalFavorite.favoriteItems.filter((f) => f.wishListId === wishListItem.wishListId);
  }

  isDefaultWishList(): any {
    if (this.wishList) {
      if (this.selectedItem) {
        return this.selectedItem.isDefaultWishList ? true : false;
      }
    }
  }

  enableAddAllToCart(): boolean {
    if (this.favorite?.favoriteItems?.length) {
      const itemsAvailable = this.favorite.favoriteItems.filter((i) => !i.isItemAlreadyInCart);
      return itemsAvailable.length > 0;
    } else {
      return false;
    }
  }

  deleteThisWishList() {
    this.showWishListItemDeleteConfirmationDialog();
  }

  renameThisWishList() {
    this.showWishListItemRenameDialog();
  }

  createWishList() {
    this.showWishListItemCreateDialog();
  }

  moveItemToWishList(itemWishList: string, favoriteItem: IFavoriteItem) {
    if (itemWishList) {
      this.loadingService.show();
      this.favoriteService.moveItemToWishList(favoriteItem.wishListId, itemWishList, favoriteItem.itemNumber).subscribe(
        (response) => {
          this.setResponse(response);
          this.selectedItem = this.wishList.find((f) => f.wishListId === favoriteItem.wishListId);
          this.filterWishList(this.selectedItem);
          this.loadingService.hide();
          this.scrollTo(this.messagesBox.nativeElement);
        },
        (error: any) => {
          this.loadingService.hide();
        }
      );
    }
  }

  wishListDisable(dataItem): any {
    if (dataItem.disable === true) {
      return true;
    }
  }

  addAllItemsToCart() {
    const items: ICartItem[] = [];

    this.favorite.favoriteItems.forEach((fi, i) => {
      items.push({
        itemNumber: fi.itemNumber,
        quantity: 1,
        sourceInfo: { ...this.sourceInfo, sourceIndex: (i + 1).toString() }
      });
    });

    this.loadingService.show();
    this.cartService.addItemsWithConfirmationDialog(items, this.onItemsAdded.bind(this));
  }

  addItemToCart(favoriteItem: IFavoriteItem, i: number) {
    this.loadingService.show();
    const item: ICartItem = {
      itemNumber: favoriteItem.itemNumber,
      quantity: 1,
      sourceInfo: { ...this.sourceInfo, sourceIndex: (i + 1).toString() }
    };

    this.cartService.addItemWithConfirmationDialog(item, this.onItemsAdded.bind(this));
  }

  addToSubscription(favoriteItem: IFavoriteItem, i: number) {
    this.loadingService.show();
    const item: ICartItem = {
      isAutoShip: true,
      autoShipFrequency: 1,
      itemNumber: favoriteItem.itemNumber,
      quantity: 1,
      sourceInfo: { ...this.sourceInfo, sourceIndex: (i + 1).toString() }
    };

    this.cartService.addItemWithConfirmationDialog(item, this.onItemsAdded.bind(this));
  }

  onItemsAdded(success: boolean, targetItems: ICartItem[]) {
    if (!success) {
      return;
    }

    targetItems.forEach((item) => {
      const favoriteItem = this.favorite.favoriteItems.find((fi) => fi.itemNumber === item.itemNumber);
      if (!favoriteItem?.isItemAlreadyInCart) {
        favoriteItem.isItemAlreadyInCart = true;
      }

      const originalItem = this.originalFavorite.favoriteItems.find((fi) => fi.itemNumber === item.itemNumber);
      if (!originalItem?.isItemAlreadyInCart) {
        originalItem.isItemAlreadyInCart = true;
      }
    });
  }

  removeItemFavorite(favoriteItem: IFavoriteItem) {
    if (favoriteItem.quantity) {
      favoriteItem.quantity = 0;
    }
    this.loadingService.show();
    this.favoriteService.removeItemFavorite(favoriteItem.lineId, favoriteItem).subscribe(
      (response) => {
        this.setResponse(response);
        this.selectedItem = this.selectedItem ? this.selectedItem : this.wishList[0];
        this.filterWishList(this.selectedItem);
        this.loadingService.hide();
      },
      (error: any) => {
        this.loadingService.hide();
      }
    );
  }

  showWishListItemDeleteConfirmationDialog() {
    if (this.selectedItem) {
      this.favoriteDialogService.openWishListDeleteDialog(this.selectedItem.wishListId, (confirm: any) => {
        if (confirm) {
          this.loadingService.show();
          this.favoriteService.removeWishList(this.selectedItem).subscribe(
            (response) => {
              this.setResponse(response);
              this.selectedItem = this.wishList[0];
              this.filterWishList(this.selectedItem);
              this.loadingService.hide();
            },
            (error: any) => {
              this.loadingService.hide();
            }
          );
        }
      });
    }
  }

  showWishListItemRenameDialog() {
    if (this.selectedItem) {
      const wishListSelected = new WishListModel();
      Object.assign(wishListSelected, this.selectedItem);
      this.favoriteDialogService.openWishListItemRenameDialog(wishListSelected, (confirm: any) => {
        if (confirm) {
          this.loadingService.show();
          this.favoriteService.renameWishList(wishListSelected.wishListId, wishListSelected.wishListName, this.selectedItem).subscribe(
            (response) => {
              this.setResponse(response);
              this.selectedItem = this.wishList.find((f) => f.wishListId === this.selectedItem.wishListId);
              this.filterWishList(this.selectedItem);
              this.loadingService.hide();
            },
            (error: any) => {
              this.loadingService.hide();
            }
          );
        }
      });
    }
  }

  setResponse(response: any) {
    this.originalFavorite = new FavoriteModel();
    this.favorite = new FavoriteModel();
    this.originalFavorite = JSON.parse(JSON.stringify(response.favoriteItemListViewModel));
    this.favorite = JSON.parse(JSON.stringify(this.originalFavorite));
    this.wishList = response.favoriteWishListViewModel;
    this.defaultItem = { wishListId: null, wishListName: 'Change List', disable: false, isDefaultWishList: false };
  }

  showWishListItemCreateDialog() {
    const newIWishListItem = new WishListModel();
    this.favoriteDialogService.openWishListItemCreateDialog(newIWishListItem, (confirm: any) => {
      if (confirm) {
        this.loadingService.show();
        this.favoriteService.createWishList(newIWishListItem.wishListName).subscribe(
          (response) => {
            this.setResponse(response);
            this.selectedItem = this.wishList.find((f) => f.wishListName === newIWishListItem.wishListName);
            this.filterWishList(this.selectedItem);
            this.loadingService.hide();
          },
          (error: any) => {
            this.loadingService.hide();
          }
        );
      }
    });
  }

  loadMore(): void {
    this.pageSize += 10;
  }

  private scrollTo(element: HTMLDivElement): void {
    const rect = element.getBoundingClientRect();
    const offset = this.responsiveService.currentSize < ScreenSizes.LG ? 60 : 165;
    window.scrollTo(0, window.scrollY + rect.y - offset);
  }
}
